import React, { useState } from "react";
import "./App.css";

function App() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);
  let winnerLines = [];
  const winner = calculateWinner(board);

  const startNewGame = () => {
    setBoard(Array(9).fill(null));
    setXIsNext(true);
  };

  const handleClick = (index) => {
    const boardCopy = [...board];
    if (winner || boardCopy[index]) return;
    boardCopy[index] = xIsNext ? "X" : "O";
    setBoard(boardCopy);
    setXIsNext(!xIsNext);
  };

  const renderSquare = (index) => {
    const squareClass =
      winnerLines.find((line) => line === index) || winnerLines[0] === index
        ? "square win " + board[index]
        : "square";
    return (
      <button className={squareClass} onClick={() => handleClick(index)}>
        <span className={board[index] === "X" ? "x" : "o"}>{board[index]}</span>
      </button>
    );
  };

  function calculateWinner(board) {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        winnerLines = [a, b, c];
        return board[a];
      }
    }
    return null;
  }

  const renderStatus = () => {
    if (winner) {
      return (
        <span>
          Winner: <span className={"winner " + winner}>Player {winner}</span>
        </span>
      );
    } else if (board.every((square) => square != null)) {
      return "Draw!";
    }
  };

  return (
    <div className="app-container">
      <div className="text-box">
        <h1 className="title">
          Let's Play <span>Tic-Tac-Toe</span> Game!
        </h1>
        <button onClick={startNewGame} type="button" className="start-btn">
          Start a New Game
        </button>
      </div>
      <div className="board-area">
        <div className="players">
          <div className={xIsNext ? "player first active" : "player first"}>
            Player <span>X</span>
          </div>
          <div className={xIsNext ? "player second" : "player second active"}>
            Player <span>O</span>
          </div>
        </div>
        <div className="board">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
        <div className="status">{renderStatus()}</div>
      </div>
    </div>
  );
}

export default App;
